<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Dashboard</h1>
    <v-layout row wrap>

    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
