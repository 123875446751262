<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Projects</h1>
    <v-layout row class="ma-0" align-center justify-end>
      <v-btn @click="$router.push({ name: 'AddNewProject' })" dark>
        <v-icon>mdi-plus</v-icon>
        Add New Project
      </v-btn>
    </v-layout>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="projects"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.services`]="{ item }">
            <v-chip
              v-for="(service, i) in item.services"
              :key="service + i"
              class="ma-1"
            >
              {{ service }}
            </v-chip>
          </template>
          <template v-slot:[`item.feature_image`]="{ item }">
            <v-avatar
              :size="64"
              class="mr-1"
              color="white"
              tile
              @click="openImagePreview(item.feature_image)"
            >
              <img :src="resizedPhoto(item.feature_image)" />
            </v-avatar>
          </template>
          <template v-slot:[`item.gallery`]="{ item }">
            <v-avatar
              :size="32"
              class="mr-1"
              color="white"
              tile
              v-for="(photo, i) in item.gallery"
              :key="photo + i"
              @click="openImagePreview(item.gallery)"
            >
              <img :src="resizedPhoto(photo)" />
            </v-avatar>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <v-dialog v-model="imagePreview" max-width="720px">
      <v-card>
        <v-carousel>
          <v-carousel-item
            v-for="(image, i) in previewImages"
            :key="i + image"
            :src="image"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Thumbnail", sortable: false, value: "feature_image" },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Gallery", sortable: false, value: "gallery" },
        { text: "Summary", value: "summary" },
      ],
      projects: [],
      imagePreview: false,
      previewImages: [],
    };
  },
  methods: {
    openImagePreview(photos) {
      this.imagePreview = true;
      this.previewImages = photos;
    },
    resizedPhoto(photo) {
      return photo.replace(
        "https://res.cloudinary.com/kamrankhalid-com/image/upload/",
        "https://res.cloudinary.com/kamrankhalid-com/image/upload/w_50,h_50,c_fill/"
      );
    },
    getProjects() {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("portfolio")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.projects.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>

<style></style>
