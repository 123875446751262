<template>
  <v-container grid-list-xs class="pa-12">
    <v-layout row class="ma-0" align-center justify-start>
      <v-btn icon @click="$router.go(-1)" class="mr-4">
        <v-icon color="black">mdi-arrow-left</v-icon>
      </v-btn>
      <h1>Add New Project</h1>
    </v-layout>
    <input
      ref="mainThumbnail"
      type="file"
      @input="mainThumbnail"
      style="display: none;"
    />
    <input
      ref="fileInput"
      type="file"
      @input="pickFile"
      multiple
      style="display: none;"
    />
    <v-card class="pa-4 my-6">
      <v-layout row wrap class="ma-0">
        <v-flex xs12 md6 v-if="projectThumbnailPreview.length == 0">
          <v-card color="grey lighten-2" flat @click="selectMainThumbnail">
            <v-responsive :aspect-ratio="1 / 1">
              <v-layout column fill-height align-center justify-center>
                <v-layout row align-center justify-center>
                  + Add Main Thumbnail
                </v-layout>
              </v-layout>
            </v-responsive>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 v-if="projectThumbnailPreview.length == 1">
          <v-img
            :aspect-ratio="1 / 1"
            :src="projectThumbnailPreview[0].url"
            :title="projectThumbnailPreview[0].url"
          >
            <v-layout
              column
              class="ma-0 pa-2"
              fill-height
              align-start
              justify-space-between
            >
              <v-layout row>
                <v-btn
                  dark
                  x-small
                  fab
                  color="red"
                  @click="removeThumbnailImage(0)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 md6>
          <v-layout row wrap class="ma-0">
            <v-flex xs12 class="px-2">
              <v-text-field
                name="projectName"
                label="Project Name"
                id="project-name"
                :messages="projectSlug"
                v-model="projectName"
                @keyup="makeTitleSlug"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 class="px-2">
              <v-text-field
                name="projectCoverImage"
                label="Project Cover Image"
                id="project-cover-image"
                v-model="projectCoverImage"
              ></v-text-field>
            </v-flex>
            <v-divider class="my-6"></v-divider>
            <v-flex xs12 class="px-2">
              <h3>Summary</h3>
              <wysiwyg v-model="projectSummary" />
            </v-flex>
            <v-divider class="my-6"></v-divider>
            <v-flex xs12 class="px-2">
              <h3>Description</h3>
              <wysiwyg v-model="projectDescription" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <v-layout row wrap class="ma-0">
        <v-flex xs6 md3>
          <v-card color="grey lighten-2" flat @click="selectImages">
            <v-responsive :aspect-ratio="1 / 1">
              <v-layout column fill-height align-center justify-center>
                <v-layout row align-center justify-center>
                  + Add Gallery Photos
                </v-layout>
              </v-layout>
            </v-responsive>
          </v-card>
        </v-flex>
        <v-flex
          xs6
          md3
          v-for="(photo, i) in projectPreviewPhotos"
          :key="photo + i"
        >
          <v-img :aspect-ratio="1 / 1" :src="photo.url" :title="photo.url">
            <v-layout
              column
              class="ma-0 pa-2"
              fill-height
              align-start
              justify-space-between
            >
              <v-layout row>
                <v-btn dark x-small fab color="red" @click="removeImage(i)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
      <v-divider class="my-6"></v-divider>
      <!-- <pre>
        {{ projectDescription }}
      </pre> -->
      <v-progress-linear
        :indeterminate="true"
        v-if="loading"
      ></v-progress-linear>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="!uploading" @click="uploadProject">
          Upload Project
        </v-btn>
        <v-btn dark v-else>
          {{ `Uploading ${uploaded} of ${projectGallery.length}` }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import slugify from "slugify";
import Axios from "axios";

export default {
  data() {
    return {
      loading: false,
      projectName: "",
      projectSlug: "",
      projectCoverImage: "",
      projectSummary: "",
      projectDescription: "",
      projectThumbnail: [],
      projectThumbnailURLs: [],
      projectThumbnailPreview: [],
      projectGallery: [],
      projectPhotoURLs: [],
      projectPreviewPhotos: [],
      loopIndex: 0,
      uploading: false,
      uploaded: 0,
    };
  },
  computed: {
    //
  },
  methods: {
    uploadProject() {
      this.uploadThumbnail();
    },
    clearAll() {
      this.loading = false;
      this.projectName = "";
      this.projectSlug = "";
      this.projectCoverImage = "";
      this.projectSummary = "";
      this.projectDescription = "";
      this.projectThumbnail = [];
      this.projectThumbnailURLs = [];
      this.projectThumbnailPreview = [];
      this.projectGallery = [];
      this.projectPhotoURLs = [];
      this.projectPreviewPhotos = [];
      this.loopIndex = 0;
      this.uploading = false;
      this.uploaded = 0;
    },
    addProjectToFirestore() {
      if (this.projectGallery.length == this.projectPhotoURLs.length) {
        let db = firebase.firestore();
        db.collection("portfolio")
          .doc(this.projectSlug)
          .set({
            cover_image: this.projectCoverImage,
            description: this.projectDescription,
            feature_image: this.projectThumbnailURLs[0],
            gallery: this.projectPhotoURLs,
            slug: this.projectSlug,
            summary: this.projectSummary,
            title: this.projectName,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
        this.loading = false;
        this.uploading = false;
        setTimeout(() => {
          this.clearAll();
        }, 2000);
      } else {
        setTimeout(() => {
          this.addProjectToFirestore();
        }, 2000);
      }
    },
    uploadThumbnail() {
      this.loading = true;
      this.uploading = true;
      setTimeout(() => {
        let publicID =
          `projects/` +
          `${this.projectName}/` +
          `${this.projectSlug}-thumbnail`;

        let formData = new FormData();
        const file = this.projectThumbnail[0];
        formData.append("file", file);
        formData.append("upload_preset", "kamrankhalid");
        formData.append("public_id", publicID);
        Axios.post(
          `https://api.cloudinary.com/v1_1/kamrankhalid-com/upload`,
          formData
        )
          .then((res) => {
            console.log(res);
            this.projectThumbnailURLs.push(res.data.secure_url);
          })
          .catch((err) => console.log({ err }));

        if (this.projectThumbnailURLs.length < this.projectThumbnail.length) {
          this.uploadThumbnail();
        } else {
          this.uploadImages();
        }
      }, 2000);
    },
    uploadImages() {
      this.loading = true;
      this.uploading = true;
      setTimeout(() => {
        let publicID =
          `projects/` +
          `${this.projectName}/` +
          `${this.projectSlug}-${this.loopIndex + 1}`;

        let formData = new FormData();
        const file = this.projectGallery[this.loopIndex];
        formData.append("file", file);
        formData.append("upload_preset", "kamrankhalid");
        formData.append("public_id", publicID);
        this.uploaded++;
        Axios.post(
          `https://api.cloudinary.com/v1_1/kamrankhalid-com/upload`,
          formData
        )
          .then((res) => {
            this.projectPhotoURLs.push(res.data.secure_url);
          })
          .catch((err) => console.log({ err }));

        this.loopIndex++;
        if (this.loopIndex < this.projectGallery.length) {
          this.uploadImages();
        } else {
          this.addProjectToFirestore();
        }
      }, 2000);
    },
    removeThumbnailImage(i) {
      const dt = new DataTransfer();
      if (i > -1) {
        for (let index = 0; index < this.projectThumbnail.length; index++) {
          const file = this.projectThumbnail[index];
          if (i !== index) dt.items.add(file); // here you exclude the file. thus removing it.
        }
        this.projectThumbnail = dt.files;
        console.log(this.projectThumbnail);
        this.projectThumbnailURLs.splice(i, 1);
        this.projectThumbnailPreview.splice(i, 1);
        // this.projectThumbnail.splice(i, 1);
        // var newFileList;
      }
    },
    removeImage(i) {
      const dt = new DataTransfer();
      if (i > -1) {
        for (let index = 0; index < this.projectGallery.length; index++) {
          const file = this.projectGallery[index];
          if (i !== index) dt.items.add(file); // here you exclude the file. thus removing it.
        }
        this.projectGallery = dt.files;
        console.log(this.projectGallery);
        this.projectPhotoURLs.splice(i, 1);
        this.projectPreviewPhotos.splice(i, 1);
        // this.projectGallery.splice(i, 1);
        // var newFileList;
      }
    },
    selectMainThumbnail() {
      this.$refs.mainThumbnail.click();
    },
    selectImages() {
      this.$refs.fileInput.click();
    },
    mainThumbnail() {
      let input = this.$refs.mainThumbnail;
      let files = input.files;
      console.log(files);
      this.projectThumbnail = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.projectThumbnailPreview.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
        console.log(this.projectThumbnailPreview);
      }
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let files = input.files;
      this.projectGallery = files;
      for (let index = 0; index < files.length; index++) {
        let file = files[index];
        this.projectPreviewPhotos.push({
          url: URL.createObjectURL(file),
          uploading: null,
        });
      }
    },
    makeTitleSlug() {
      this.projectSlug =
        slugify(this.projectName, {
          replacement: "-",
          lower: true,
        }) + `-${new Date().getTime()}`;
    },
    getCategories() {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("categories")
        .where("active", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style></style>
