<template>
  <v-container grid-list-xs class="pa-12">
    <h1>Messages</h1>
    <v-divider class="my-6"></v-divider>
    <v-layout row class="ma-0">
      <v-card width="100%">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="messages"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
          single-expand
          :expanded.sync="expanded"
          item-key="time.seconds"
          show-expand
        >
          <template v-slot:[`item.timestamp`]="{ item }">
            <span
              >{{ new Date(item.timestamp.seconds * 1000).toLocaleString() }}
            </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="pa-4" :colspan="headers.length">{{ item.message }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      loading: false,
      search: "",
      expanded: [],
      headers: [
        { text: "Name", sortable: false, value: "name" },
        { text: "Email", value: "email" },
        // { text: "Phone Number", value: "phoneNumber" },
        { text: "Timestamp", value: "timestamp" },
        { text: "", value: "data-table-expand" },
      ],
      messages: [],
    };
  },
  methods: {
    getMessages() {
      this.loading = true;
      let db = firebase.firestore();
      db.collection("messages")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.messages.push({ id: doc.id, ...doc.data() });
          });
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getMessages();
  },
};
</script>

<style></style>
