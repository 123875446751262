<template>
  <v-container grid-list-xs>
    <v-layout row wrap align-center justify-center class="ma-0 pa-0">
      <v-flex xs12 sm8 md6 lg4>
        <v-form ref="form" v-model="valid">
          <h1 class="pa-6 text-center">Login</h1>
          <v-text-field
            name="email"
            label="Email"
            id="email"
            type="email"
            v-model="email"
            :rules="emailRules"
            required
            autofocus
          ></v-text-field>
          <v-text-field
            name="password"
            label="Password"
            id="password"
            :type="passwordVisible ? 'text' : 'password'"
            v-model="password"
            :rules="passwordRules"
            required
            append-icon="mdi-eye"
            @click:append="showPassword"
          ></v-text-field>
          <v-btn
            class="my-2"
            block dark
            @click="login"
            :disabled="disabled"
            rounded
          >
            Login
          </v-btn>
          <!-- <v-btn class="my-2" color="primary" block to="Register">
            Register
          </v-btn> -->
        </v-form>
        <v-alert type="error" v-if="error">{{ error }}</v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  data() {
    return {
      valid: true,
      passwordVisible: false,
      email: "administrator@inoverus.com",
      password: "inoverus@admin",
      error: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 8) ||
          "Password must be more than or equal to 8 characters",
      ],
    };
  },
  computed: {
    disabled() {
      return !this.valid;
    },
  },
  methods: {
    showPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    async login() {
      this.error = null;
      try {
        const user = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        // this.$router.replace({ name: "Home" });
        console.log(user);
        location.reload();
      } catch (error) {
        console.error(error);
        this.error = error.message;
      }
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.$router.replace({ name: "Home" });
    }
  },
};
</script>

<style></style>
