<template>
  <v-app>
    <Navigation v-if="loggedIn" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Navigation from "./components/Navigation.vue";

export default {
  name: "App",
  components: { Navigation },
  data: () => ({
    //
  }),
  computed: {
    loggedIn() {
      return firebase.auth().currentUser;
    },
  },
};
</script>
<style>
.v-image__image--cover {
  background-size: contain;
}
</style>
