<template>
  <nav>
    <v-app-bar app dark clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Kamran Khalid</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn text @click="logout">Logout</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" dark app clipped>
      <v-layout row align-center justify-center class="ma-0 pa-12">
        <!-- <v-avatar size="64" tile>
          <img
            :src="require('../assets/Inoverus-Icon.png')"
            alt="Inoverus Icon"
          />
        </v-avatar> -->
      </v-layout>

      <v-list>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "App",
  data: () => ({
    drawer: true,
    items: [
      { title: "Dashboard", route: "/" },
      { title: "Projects", route: "/projects" },
      { title: "Messages", route: "/messages" },
    ],
  }),
  methods: {
    logout() {
      firebase.auth().signOut();
      // this.$router.replace({ name: "Login" });
      location.reload();
    },
  },
};
</script>
